import React from 'react'

function UpcomingEvent(props) {
  return (
    <>

      <div className={`container-fluid mt-4 bg-inherited
    ${props.background === 1 ? `event-wallpaper` : ``}`}
      >
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-12 col-lg-4 d-flex justify-content-center  '>
            <div className='upcoming-image-div  border border-dark' data-bs-toggle="modal" data-bs-target="#magnify" >
              <img className='upcoming-event' src="./image/puri yatra.jpg" alt="puri yatra" />
              <img class="magnify mx-auto " src="./image/magnify.svg" alt="magnify"></img>
            </div>
            <div class="modal fade modal-md" id="magnify" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  {/* <div class="modal-header">
                    
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div> */}
                  <div class="modal-body">
                  <img className='img-fluid' src="./image/puri yatra.jpg" alt="puri yatra" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className='col-11 col-lg-6 d-flex flex-column justify-content-center align-items-sm-start ps-5'>
            <p><i className='fa fa-bookmark mt-3'></i> Registration Closed</p>

            <p><i className='fa fa-bookmark'></i> Join the telegram group to get yatra updates</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpcomingEvent
import React from "react";
import Navbar from './Navbar'

function Courses() {
    return (
        <>
            <Navbar />
            {/* <div className="coursesbg container-fluid">

                <div className=" d-flex justify-content-center align-items-center">

                    <div className='col-12 col-md-12 col-lg-6 pt-3'>
                        <p className='mt-5  text-light shadow-text tanger d-1'>Courses </p>

                    </div>

                </div>

            </div> */}
            <div className='font-lato brown-wallpaper '>
                <div className="container">
                    <div class="slide-effect d-flex  flex-column justify-content-center">
                        <div class="slideDown">Systematic Education</div>
                        <div className="fw-bold fs-1">Of</div>
                        <div class="slideUp ">Spiritual Knowledge</div>
                    </div>
                </div>
            </div>
            <div className='font-lato bg-white '>
                <div className="container d-flex">
                    <div className="row d-flex bg-white my-5">


                        <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center p-2">

                            <img src="https://iskconchowpatty.com/wp-content/uploads/2020/03/Srila-Prabhupada-as-a-perfect-gentleman.jpg" className="img-fluid" alt="Srila Prabhupada" />




                        </div>
                        <div className="col-12 col-lg-7 d-flex flex-column p-3  ">

                            <p className="  ">
                                His Divine Grace A. C. Bhaktivedanta Swami Prabhupada  often expressed his thoughts:
                            </p>
                            <div className="d-flex ">
                                <div class="vl"></div>
                                <i className="ms-2 text-muted">“We are traveling all over the world, but there is no university, no institution, no school, no academy where the education of spiritual nature is imparted.”</i>
                            </div>
                            <p className=" mt-2 ">
                                Therefore he considered the establishment of a Vedic academy very essential. Hence a forum has been established for this purpose of conducting courses for a systematic study of vedic literatures, and various other courses on lifestyle management and cultural training.
                            </p>

                        </div>
                    </div>



                </div>
                <div className="container text-center fw-bold fs-1 my-2">SCRIPTURAL COURSES</div>
                <div class="container container2 d-flex justify-content-evenly flex-wrap">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdLLiSQC_jfi8u15LoMwNI8MsRzzk4NSYj9pApEz_tKGGqdGg/viewform?usp=sf_link" target="_blank" className="text-decoration-none text-dark">
                        <div class="card">
                            <div class="content">
                                <div class="imgBx">
                                    <img class="img-fluid" src="./image/discoverurself.jpg" alt="discover yourself"/>
                                </div>
                                <div class="contentBx d-flex flex-column justify-content-center">
                                    <h3 className="fw-bold">DISCOVER YOURSELF</h3>
                                    <p className="p-3 text-center">This is an introductory course to Bhagavad Gita covering in brief the 5 essential subject matters:- God, Soul, Nature, Time and Karma. One will find immediate answers to the deepest unanswered questions of life.</p>
                                    <p className="mx-auto">Click to register</p>
                                </div>
                            </div>

                        </div>
                    </a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScUbKTxicXMqfFON7V01l1gIE2aeQN6DqH6m1lT_w27IadJzA/viewform?usp=sf_link" target="_blank" className="text-decoration-none text-dark">
                        <div class="card">
                            <div class="content">
                                <div class="imgBx">
                                    <img class="img-fluid" src="https://bhaktivedantacollege.com/wp-content/uploads/2022/03/bs_small_v1.jpg" alt="bhagavad gita"/>
                                </div>
                                <div class="contentBx d-flex flex-column justify-content-center">
                                    <h3 className="fw-bold">BHAGAVAD GITA</h3>
                                    <p className="p-3 text-center">This course will teach in depth the 18 chapters of Bhagavad Gita in 18 weeks. It also includes the corresponding spiritual practice to understand the confidential science of the Gita</p>

                                    <p className="mx-auto">Click to register</p>
                                </div>
                            </div>

                        </div>
                    </a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeTmVKFcfBWrD90jaSd1KzrlRpi8q7thr9SySh2OYJkIOMx1w/viewform?usp=sf_link" target="_blank" className="text-decoration-none text-dark">
                    <div class="card">
                        <div class="content">
                            <div class="imgBx">
                                <img class="img-fluid" src="./image/srimadbhagvatam.jpg" alt="srimad bhagvatam"/>
                            </div>
                            <div class="contentBx d-flex flex-column justify-content-center">
                                <h3 className="fw-bold">SRIMAD BHAGVATAM</h3>
                                <p className="p-3 text-center">This is the systematic course of the elevated devotional literature. The course contains the wonderful teachings and practices of celebrated transcendentalist of ancient times.</p>
                                <p className="mx-auto">Click to register</p>
                            </div>
                        </div>

                    </div>
                    </a>

                </div>
            </div>

        </>
    )
}
export default Courses
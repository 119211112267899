import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from 'react';
import { useState } from 'react';


function Workicon() {
  return (
    <div className="p-2">
      <img src="./image/lotus.ico" className='img-fluid'></img>
    </div>
  )

}
function Subscribe() {
  const [videoState,setVideoState] = useState(1);
    const closeVideo = (()=>{
        setVideoState(0);
    });
    const openVideo = (()=>{
        setVideoState(1);
    })
  return (
    <>
     {/* <div id="video1" class="modal fade modal-lg"tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        
                        <div class="modal-body">
                            <div class="container1">
                            <iframe className="responsive-iframe"  src="https://www.youtube.com/embed/linjf5fTMY0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="video2" class="modal fade modal-lg"tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        
                        <div class="modal-body">
                            <div class="container1">
                            <iframe className='responsive-iframe' src="https://www.youtube.com/embed/o-n1zpsnJFA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="video3" class="modal fade modal-lg"tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        
                        <div class="modal-body">
                            <div class="container1">
                            <iframe className='responsive-iframe' src="https://www.youtube.com/embed/XqynuBNmxaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    <div id="video1" class="modal video-modal fade modal-lg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header py-0">
                            <h5 class="modal-title tanger ms-auto display-6" id="exampleModalLabel">Radhastami 2022</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" onClick={closeVideo}>

                            </button>
                        </div>
                        <div class="modal-body">
                            {videoState === 1? (<div class="container1" id="vid1">
                                <iframe className="responsive-iframe" src="https://www.youtube.com/embed/linjf5fTMY0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>):null}
                        </div>
                    </div>
                </div>
            </div>
            <div id="video2" class="modal video-modal fade modal-lg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header py-0">
                            <h5 class="modal-title tanger ms-auto display-6" id="exampleModalLabel">Turning from Ice-cream to Candle</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" onClick={closeVideo}>

                            </button>
                        </div>
                        <div class="modal-body">
                        {videoState === 1? (<div class="container1" id="vid1">
                                <iframe className='responsive-iframe' src="https://www.youtube.com/embed/o-n1zpsnJFA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>):null}
                        </div>
                    </div>
                </div>
            </div>
            <div id="video3" class="modal video-modal fade modal-lg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header py-0">
                            <h5 class="modal-title tanger ms-auto display-6" id="exampleModalLabel">Govardhan Puja 2022</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" onClick={closeVideo}>

                            </button>
                        </div>
                        <div class="modal-body">
                        {videoState === 1? (<div class="container1" id="vid1">
                                <iframe className='responsive-iframe' src="https://www.youtube.com/embed/XqynuBNmxaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>):null}
                        </div>
                    </div>
                </div>
            </div>





    <div className='bg-1'>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(255, 145, 189)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(255, 145, 189)' }}
          date="4th September, 2022"
          iconStyle={{ background: 'rgb(248, 200, 220)', color: '#fff' }}
          icon={<Workicon />}

        >
          <h3 className="vertical-timeline-element-title">Radhastami Celebrations</h3>
          <h4 className="vertical-timeline-element-subtitle">At ..</h4>
          <p>
            Beautiful celebrations on the occassion of appearance day of Srimati Radharani.
          </p>
          <div className='position-relative cursor-pointer text-center' data-bs-toggle="modal" data-bs-target="#video1" onClick={openVideo}>
                <img className='img-thumbnail' src="./image/radhastami.jpg" alt="radhashtami" />
                <img className='play-button-1' src="./image/play-button.svg" alt="play button" />
            </div>
          
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(255, 145, 189)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(255, 145, 189)' }}
          date="12th November, 2022"
          iconStyle={{ background: 'rgb(248, 200, 220)', color: '#fff' }}
          icon={<Workicon />}

        >
          <h3 className="vertical-timeline-element-title">Turn your life from ice-cream to candle</h3>
          <h4 className="vertical-timeline-element-subtitle"></h4>
          <p>
            Do you want your life to be like an ice-cream, enjoy it before it melts or like a candle, that removes darkness?
          </p>
          <div className='position-relative cursor-pointer text-center'data-bs-toggle="modal" data-bs-target="#video2" onClick={openVideo}>
                <img className='img-thumbnail' src="./image/ice.jpg" alt="ice" />
                <img className='play-button-1' src="./image/play-button.svg" alt="play button" />
            </div>
          
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(255, 145, 189)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(255, 145, 189)' }}
          date="26th October,2022"
          iconStyle={{ background: 'rgb(248, 200, 220)', color: '#fff' }}
          icon={<Workicon />}

        >
          <h3 className="vertical-timeline-element-title">Govardhan puja</h3>
          <h4 className="vertical-timeline-element-subtitle"></h4>
          <p>
           Take a look at the preparations and esctatic celebration of Govardhan puja
          </p>
          <div className='position-relative cursor-pointer text-center'data-bs-toggle="modal" data-bs-target="#video3" onClick={openVideo}>
                <img className='img-thumbnail' src="./image/govardhan.jpg" alt="govardhan" />
                <img className='play-button-1' src="./image/play-button.svg" alt="play button" />
            </div>
          
        </VerticalTimelineElement>
        
      </VerticalTimeline>
    </div>
    </>
  )
}

export default Subscribe
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Welcome() {
  const navigate = useNavigate();
  const goToAboutus = (() => {
    navigate("/about-us")
  })

  return (
    <>
      <div className='bg-pantone py-5'>
        <h1 className="text-center text-white font-lato fw-bold">WELCOME</h1>
        <div className='container text-center mt-5 '>
          <div className=''>
            <p >
              Hare Krishna! Welcome to Sri Sri Radha Madangopal Temple, home to the loving family of devotees who are leading a life of spiritual values based on the teachings of His Divine Grace A.C. Bhaktivedanta Swami Prabhupada.
            </p>
          </div>
          {/* <p>
            Due to the recent outbreak of the COVID-19 pandemic, we had to close our gates for our visitors in the interest of public health and safety. But we can’t wait to see you all once again come and feel blessed seeing the main Darshan, go-around the complex seeing the Laxmi Narayan temple, the Vrindavan forest and the diaroma exhibits. Visiting for any of the daily aratis or joining us for the numerous festivals we celebrate. We also conduct a variety of courses, seminars and workshops. In fact, there’s something for everyone – the kids, the youth, the families. We also invite you to dine at our international restaurant, celebrate your occasion at the temple or join us for any of our tours. Together, let’s enhance the spiritual dimension of our lives!
          </p> */}
          <ol type="1" className='text-start w-big mx-auto'>
            <p className='text-center'>We aspire to abide by the 7 purposes highlighted by His Divine Grace:-</p>
            <li className='mt-3'>
              To systematically propagate spiritual knowledge to society at large and to educate all people in the techniques of spritual life in order to check the imbalance of values in life, and to achieve real unity and peace in the world.
            </li>
            <li className='mt-3'>
              To propagate a consciousness of Kṛṣṇa as He is revealed in Bhagavad-gita and Srimad Bhagavatam.
            </li>
            <li className='mt-3'>
              To bring the members of the society together with each other and nearer to Krsna, and thus to develop the idea within the members and humanity at large that each soul is part and parcel of the Supreme Personality of Godhead.
            </li>
            <li className='mt-3'>
              To teach and encourage the sankirtan movement, congregational chanting of the holy name of God, and to reveal the teachings of Lord Caitanya Mahaprabhu.
            </li>
            <li className='mt-3'>
              To erect for the members, and the society at large, a holy place of transcendental pastimes, dedicated to the Personality of Godhead.
            </li>
            <li className='mt-3'>
              To bring the members closer together for the purpose of teaching a simple and more natural way of life.
            </li>
            <li className='mt-3'>
              With a view towards achieving the aforementioned purposes, to publish and distribute periodicals, magazines, books and other writings.
            </li>
          </ol>
        </div>
        <div className='px-5 d-flex justify-content-center mt-5'>
          <div>
            <button className='btn rounded-pill btn-outline-dark py-3 px-4' onClick={goToAboutus}>About Us</button>
          </div>
          {/* <div>
            <button className='btn rounded-pill btn-outline-dark p-3'>Our Projects</button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Welcome
import React, { useRef } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";

function Navbar() {
    let closeModal = useRef();
    let userNameRef = useRef();
    let emailRef = useRef();
    let amountRef = useRef();
    let phoneRef = useRef();
    const navigate = useNavigate();
    const goToEvent = (() => {
        navigate("/events");
    });
    const goToHome = (() => {
        navigate("/");
    })
    const goToContactus = (() => {
        navigate("/contact-us")
    })
    const goToAboutus = (() => {
        navigate("/about-us")
    })
    const goToCourses = (() => {
        navigate("/courses")
    })


    //Payment methods
    let loadScript = async () => {
        const scriptElement = document.createElement("script");
        scriptElement.src = "https://checkout.razorpay.com/v1/checkout.js";
        scriptElement.onload = () => { return true };
        scriptElement.onerror = () => { return false };
        document.body.appendChild(scriptElement);
    }
    let makePayment = async () => {
        console.log(process.env.REACT_APP_API_URL + "payment");
        let isLoaded = await loadScript();
        if (isLoaded) {
            alert('unable to load');
            return false;
        }
        let URL = process.env.REACT_APP_API_URL + "payment";
        let sendData = {
            "amount": "" + amountRef.current.value,
            "email": "" + emailRef.current.value,
            "name": "" + userNameRef.current.value,
            "phone": "" + phoneRef.current.value
        }
        closeModal.current.click();
        // console.log("this url:-"+URL);
        try {
            // console.log("this url:-"+URL);
            let { data } = await axios.post(URL, sendData);
            let { order } = data;
            console.log("i was here");
            var options = {
                key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
                amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Radha Madangopal Temple",
                description: "Donation Portal",
                image: "/image/lotus",
                order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                handler: async (response) => {
                    let URL = process.env.REACT_APP_API_URL + "callback";
                    let sendData = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature
                    }
                    try {
                        let paymentStatus = await axios.post(URL, sendData);
                        let { data } = paymentStatus;
                        let { signatureIsValid } = data;
                        if (signatureIsValid == true) {
                            let URL2 = process.env.REACT_APP_API_URL + "verify";
                            let verifyAction = await axios.post(URL2, {
                                name: userNameRef.current.value,
                                email: emailRef.current.value,
                                phone: phoneRef.current.value,
                                verifyStatus: signatureIsValid,
                                razorpay_order_id: sendData.razorpay_order_id,
                                razorpay_payment_id: sendData.razorpay_payment_id,
                                razorpay_signature: sendData.razorpay_signature
                            })
                            if (verifyAction) {
                                Swal.fire({
                                    icon: "success",
                                    title: "Payment Successful",
                                }).then(() => {
                                    // window.location.assign("/");
                                    window.location.reload();
                                });
                            }
                            else{
                                Swal.fire({
                                    icon: "error",
                                    title: "Payment Unsuccessful!Try Again.",
                                }) 
                            }
                        }
                    } catch (error) {
                        alert("error");
                        console.log(error);
                    }
                },
                prefill: {
                    name: userNameRef.current.value,
                    email: emailRef.current.value,
                    contact: phoneRef.current.value
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#3399cc"
                }
            };
            var paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
            <div
                className="modal fade font-lato"
                id="donate"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel2"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ms-auto" id="exampleModalToggleLabel2">
                                Donate
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref={closeModal}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-2">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Full Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter full Name"
                                    ref={userNameRef}
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Email"
                                    ref={emailRef}
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Phone Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter 10-digit Phone Number"
                                    ref={phoneRef}
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                >
                                    Amount&#40; in <i className='fa fa-rupee fa-2xs'></i> &#41;
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter donation amount"
                                    ref={amountRef}
                                    required
                                />
                            </div>

                            {/* <div className="mb-2">
                                <label
                                    htmlFor="exampleFormControlTextarea1"
                                    className="form-label"
                                >
                                    Address
                                </label>
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    ref={addressRef}
                                ></textarea>
                            </div> */}
                            {/* <div className="mb-2">
                                <h3>Course: {courseDetails.title}</h3>
                            </div> */}
                            {/* <div className="mb-2 ">
                                <h3>Amount <i className='fa fa-rupee'></i>{courseDetails.fees}</h3>
                            </div> */}
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-danger"
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal"
                            >
                                Back
                            </button>
                            <button
                                className="btn btn-success"
                                onClick={makePayment}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="modal modal-lg fade" tabindex="-1" id="donationModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Donation form</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form class="row g-3">
                                <div class="col-12">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" required/>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputEmail4" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="inputEmail4" required/>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputPhone" class="form-label">Phone number</label>
                                    <input type="number" class="form-control" id="inputPhone" required/>
                                </div>
                                <div class="col-6">
                                    <label for="inputAmount" class="form-label">Amount(in rupees)</label>
                                    <input type="number" class="form-control" id="inputAmount" required/>
                                </div>
                                <div class="col-12">
                                    <p>**Donations are eligible for tax exemption under Section 80G of Income Tax Act</p>
                                </div>


                                <div class="col-12 d-flex justify-content-center">
                                    <button type="submit" class="btn btn-success">Proceed to pay</button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div> */}

            <div className="container-fluid ">
                <a className="navbar-brand ms-5" href="#" onClick={goToHome}>
                    <img className='logo' src='./image/rmglogo.png' alt="rmglogo"></img>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse hover-yellow" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-5 mb-2 mb-lg-0 font-medium">
                        {/* Donate button will be activated later */}
                        {/* <li className="nav-item me-5 py-0">
                            <button className='btn rounded-pill btn-outline-warning px-5 mt-1' data-bs-toggle="modal" data-bs-target="#donate">Donate</button>
                        </li> */}
                        <li className="nav-item me-5" onClick={goToEvent}>
                            <a className="nav-link" aria-current="page" href="#">Events</a>
                        </li>
                        {/* <li className="nav-item me-5">
                            <a className="nav-link" aria-current="page" href="#">Projects</a>
                        </li> */}

                        <li className="nav-item me-5" onClick={goToCourses}>
                            <a className="nav-link" aria-current="page" href="#">Courses</a>
                        </li>
                        <li className="nav-item me-5" onClick={goToAboutus}>
                            <a className="nav-link" aria-current="page" href="#">About Us</a>
                        </li>
                        <li className="nav-item me-5" onClick={goToContactus}>
                            <a className="nav-link" aria-current="page" href="#">Contact Us</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="#">Link</a>
                        </li> */}
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li className="nav-item me-5">
                            <a className="nav-link disabled">Disabled</a>
                        </li> */}
                    </ul>
                    {/* <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> */}
                </div>
            </div>
        </nav>
    )
}

export default Navbar
import React from 'react'
import Navbar from './Navbar'
import PastEvents from './PastEvents'
import '../css/event.css'
import UpcomingEvent from './UpcomingEvent'
function Events() {
  return (
    <>
      <Navbar />
      {/* <div class="bg-1">
        <div class="container pt-2 mb-4 ">
          <h2>Upcoming event</h2>

          <div class="row mt-4 upcoming d-flex">
            <div class="col-sm-8">
              <div id="myCarousel" class="carousel slide" data-ride="carousel">



                <div class="carousel-inner" role="listbox">
                  <div class="item active">
                    <img src="./image/puri yatra.jpg" class="responsive img-fluid" alt="Image" />
                    
                  </div>


                </div>


              </div>
            </div>
            <div class="col-sm-4 mb-3">
              <div >
                <p>Some text..</p>
              </div>
              <div >
                <p>Upcoming Events..</p>
              </div>
              <div>
                <p>Visit Our Blog</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='d-flex flex-column  event-wallpaper justify-content-center pt-3'>
        <div>
        <h2 className='text-center '>UPCOMING EVENT</h2>
        </div>
        <div className='container-fluid'>
          <div className="row d-flex justify-content-center ">
            <div className="col-12 col-lg-4 d-flex justify-content-center border border-dark">
            <img src="./image/puri yatra.jpg" class="upcoming-event" alt="Image" />
            </div>
            <div className="col-12 col-lg-4 border border-dark">
              <p>Registration Closed</p>
            </div>
          </div>

        </div>

      </div> */}
      <div className=''>
      <div className='event-wallpaper pt-3'>
        <h2 className='text-center font-lato fw-bold '>UPCOMING EVENT</h2>
        <UpcomingEvent/>
      
      
      
      <svg className="waves " xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="parallax">

          <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(0, 133, 228,0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb(6, 26, 120,0.7)" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(4, 4, 88,0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(0,0,0)" />

        </g>
      </svg>
      </div>
      </div>
      <div className='p-5 my-5 text-white text-center font-lato '> <h2><u>EVENT TIMELINE</u></h2></div>
      <hr />
      <div className='border border-white border-5'>
      <PastEvents />
      </div>
    </>
  )
}

export default Events
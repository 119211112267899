import React from 'react'
import { useEffect } from 'react';
function Social() {
    let loadScript = async () => {
        const scriptElement = document.createElement("script");
        scriptElement.src = "https://widgets.sociablekit.com/youtube-channel-videos/widget.js";
        scriptElement.onload = () => { return true };
        scriptElement.onerror = () => { return false };
        document.body.appendChild(scriptElement);
    }
    // let loadScript2 = async () =>{
    //     const scriptElement = document.createElement("script");
    //     scriptElement.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v15.0";
    //     scriptElement.onload = () => { return true };
    //     scriptElement.onerror = () => { return false };
    //     document.body.appendChild(scriptElement);
    // }
    let loadScriptfunction = async () => {
        let isLoaded = await loadScript();
        // let isLoaded2 = await loadScript2();
        if (isLoaded) {
            alert('unable to load');
            return false;
        }
    }
    useEffect(() => {
        loadScriptfunction();
    }, [])
    return (
        <div className='pt-5 bg-danger'>
            <div id="fb-root"></div>
            <div className=''>
                <h2 className='text-center text-light tanger '>Social Updates</h2>
            </div>
            <div className='d-flex justify-content-evenly mt-3 flex-wrap '>
                <div className='hw-500 border border-5 border-warning '>
                    
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdaujikripadas2022&tabs=timeline&width=395&height=624&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId=1131376250861588" width="395" height="624" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                
                <div className='hw-5002 border border-5 border-warning px-auto'>

                    <div class='sk-ww-youtube-channel-videos' data-embed-id='110241'></div>
                </div>
            </div>

        </div>
    )
}

export default Social
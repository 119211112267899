import React from 'react'

function Wallpaper() {
  return (

    <div className="header">
      <div className="container-fluid">
        <div className="row d-flex flex-row justify-content-evenly align-items-center">
        <div className='col-12 col-md-12 col-lg-6'>
            <img className="deity" src="./image/RMG framed.png" alt="rmg frame" />
          </div>
          <div className='col-12 col-md-12 col-lg-6 pt-3'>
            <h2 className='mt-2  text-light '>Welcome to </h2>
            <h1 className='text-light shadow-text'>Sri Radha Madan Gopal Temple</h1>
          </div>
         
        </div>
      </div>
      <div>

        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
           
            {/* <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(0, 133, 228,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb(6, 26, 120,0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(4, 4, 88,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(0,0,0)" /> */}
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(0,128,255,1)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb( 255,240,132,1)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(170, 255, 0)" />
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(255, 172, 28)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb(255, 0, 255)" />
            {/* <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(255, 255, 255)" /> */}
           
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(0,0,0)" />
            rgba(255, 49, 49,0.5)
            rgba(15, 255, 80,0.7)
            rgba(255,255,255,0.5)
            rgba(255, 255, 143,1)
          </g>
        </svg>
      </div>
    </div>

  )
}

export default Wallpaper
import React, { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';


function EventCarousel() {
    const [videoState,setVideoState] = useState(1);
    const closeVideo = (()=>{
        setVideoState(0);
    });
    const openVideo = (()=>{
        setVideoState(1);
    })
    
    return (
        <>
            <div id="video1" class="modal video-modal fade modal-lg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header py-0">
                            <h5 class="modal-title tanger ms-auto display-6" id="exampleModalLabel">Radhastami 2022</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" onClick={closeVideo}>

                            </button>
                        </div>
                        <div class="modal-body">
                            {videoState === 1? (<div class="container1" id="vid1">
                                <iframe className="responsive-iframe" src="https://www.youtube.com/embed/linjf5fTMY0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>):null}
                        </div>
                    </div>
                </div>
            </div>
            <div id="video2" class="modal video-modal fade modal-lg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header py-0">
                            <h5 class="modal-title tanger ms-auto display-6" id="exampleModalLabel">Turning from Ice-cream to Candle</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" onClick={closeVideo}>

                            </button>
                        </div>
                        <div class="modal-body">
                        {videoState === 1? (<div class="container1" id="vid1">
                                <iframe className='responsive-iframe' src="https://www.youtube.com/embed/o-n1zpsnJFA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>):null}
                        </div>
                    </div>
                </div>
            </div>
            <div id="video3" class="modal video-modal fade modal-lg" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header py-0">
                            <h5 class="modal-title tanger ms-auto display-6" id="exampleModalLabel">Govardhan Puja 2022</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" onClick={closeVideo}>

                            </button>
                        </div>
                        <div class="modal-body">
                        {videoState === 1? (<div class="container1" id="vid1">
                                <iframe className='responsive-iframe' src="https://www.youtube.com/embed/XqynuBNmxaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>):null}
                        </div>
                    </div>
                </div>
            </div>
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000} selectedItem={0} showStatus={false} showArrows={true} swipeable={true}
            // renderArrowPrev={(clickHandler, hasPrev, label) =>
            //     hasPrev && (

            //             <img 
            //                 style={{ position: "absolute",height: "30px",width: "30px", left: "-5%",top: "40%",zIndex: 1}}
            //                 src={`./image/left arrow.svg`} />

            //     )
            // }
            // renderArrowNext={(clickHandler, hasNext, label) =>
            //     hasNext && (

            //             <img
            //                 style={{ position: "absolute",height: "30px",width: "30px", top: "40%",right: "-5%",zIndex: 1}}
            //                 src={`./image/right arrow.svg`} />

            //     )
            // }
            // renderThumbs={() => null}





            >

                <div className='position-relative cursor-pointer' data-bs-toggle="modal" data-bs-target="#video1" onClick={openVideo}>
                    <img className='carousel-image ' src="./image/radhastami.jpg" alt="radhashtami" />
                    <img className='play-button' src="./image/play-button.svg" alt="play button" />
                </div>
                <div className='position-relative cursor-pointer' data-bs-toggle="modal" data-bs-target="#video2" onClick={openVideo} >
                    <img className='carousel-image ' src="./image/ice.jpg" alt="ice" />
                    <img className='play-button' src="./image/play-button.svg" alt="play button" />
                </div>
                <div className='position-relative cursor-pointer' data-bs-toggle="modal" data-bs-target="#video3" onClick={openVideo}>
                    <img className='carousel-image ' src="./image/govardhan.jpg" alt="govardhan" />
                    <img className='play-button' src="./image/play-button.svg" alt="play button" />
                </div>


                {/* <div>rohan comments</div> */}
            </Carousel>
        </>
    )

}

export default EventCarousel
import React from "react";
import Navbar from './Navbar'

function AboutUs() {
    return (
        <>
            <Navbar />
            <div className="contactusbg container-fluid">

                <div className=" d-flex justify-content-center align-items-center">

                    <div className='col-12 col-md-12 col-lg-6 pt-3'>
                        <p className='mt-5  text-light shadow-text tanger d-1'>About us </p>

                    </div>

                </div>

            </div>
            <div className='font-lato event-wallpaper pt-4'>
                <div className="container">
                    <div className="row d-flex justify-content-center py-2">
                        <div className="col-12 col-lg-7 d-flex flex-column  p-3 ">
                            <h2 className="mt-5 fw-bold">A Humble Beginning...</h2>
                            <p className="  ">
                                {/* Today Sri Sri Radha Gopinath Mandir stands magnificently as part of the South Mumbai locale, but its beginning was very plain and humble. The seed was sown when the Trustees of the Lady Northcote Hindu Orphanage (LNHO), under the Chairmanship of Sri Arvind N. Mafatlal, invited devotees to help run the orphanage on the lines of traditional Gurukul system. Dr. N. D. Desai (H. G. Sri Nathji Das) was then the Managing Trustee of the LNHO. The Trustees gave ISKCON a small room at the back. A few devotees came to stay here in this small space with their meager belongings. */}
                                Sri Sri Radha Madan Gopal Temple Center is a seedling born out of the nourishment of the collective efforts of the group of devotees headed by His Grace Dauji Kripa Prabhu. The seed was sown when devotees of Kolkata arranged for devotees to establish a center for the propagation of the spiritual knowledge based on the teaching of His Divine Grace A C Bhaktivedanta Swami Prabhupada.
                            </p>

                        </div>

                        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center py-5">
                            <div class="polaroid">
                                <img src="./image/temple.jpg" className="img-fluid" alt="temple" />

                            </div>


                        </div>
                    </div>
                    <div className="row d-flex justify-content-center my-2">


                        <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center py-5 ">
                            <div class="polaroid">
                                <img src="./image/alldevotees.jpg" className="img-fluid" alt="temple" />

                            </div>


                        </div>
                        <div className="col-12 col-lg-5 d-flex flex-column  p-3 ">
                            <h2 className="mt-5 fw-bold">Our Temple</h2>
                            <p className="  ">
                                {/* It is modeled after 17 temples of Vrindavan, which were built by wealthy landlords and kings from Rajasthan. Vasudev was the contractor, coming from a Sampura family (who specializes in temple architecture). The stone is sandstone from Bharatpur Rajasthan. The artisans were from Orissa, and the carving of the stone was done in Pattan Gujarat using neumatic (air pressure) drills. */}
                                Our temple is located at Flat A2 407 Merujeen Housing complex, Kamalgachi. Devotees here are constantly engaged in the services of Sri Sri Madan Gopal and Giriraj Govardhan. As of yet the center has be conducting regular yatras, spiritual seminars, festivals and many more activities. Hopefully by the blessings of Lordships and devotees, we will be able to procure bigger premises in near future.
                            </p>

                        </div>
                    </div>

                </div>
                <div className="d-block bg-pantone d-flex justify-content-center align-items-center py-4">
                    <h3 className="fw-bold">HOW TO REACH<i class="fa fa-arrow-right"></i></h3>
                </div>
                <div className="container mt-3">
                    <h2 className="fw-bolder text-center py-3 font-large">OUR INSPIRATIONS</h2>
                    <div className="row d-flex justify-content-center my-2">


                        <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center py-5 ">
                            <div class="polaroid">
                                <img src="https://www.iskconkolhapur.org/assets/SrilaPrabhupada1.jpg" className="img-fluid" alt="temple" />

                            </div>


                        </div>
                        <div className="col-12 col-lg-5 d-flex flex-column   ">
                            <h2 className="mt-5 fw-bold">His Divine Grace A.C. Bhaktivedanta Swami Prabhupada</h2>
                            <p className="  ">
                                Srila Prabhupada (1896-1977), was an extraordinary person who dedicated his life for teaching the world about Krishna consciousness, ancient India’s most noble message of spiritual wisdom. In 1965, at the age of 69, on the order of his spiritual master Srila Bhaktisiddhanta Saraswati Thakura, representing a line of teachers dating back to Lord Krishna Himself, Srila Prabhupada sailed from India to New York to share Lord Krishna’s message.</p>

                        </div>
                    </div>
                    <div className="row d-flex justify-content-center my-2">
                        <div className="col-12 col-lg-5 d-flex flex-column   ">
                            <h2 className="mt-5 fw-bold">HH Radhanath Swami</h2>
                            <p className="  ">
                                Radhanath Swami is one of today’s most beloved and respected spiritual teachers. He spent his youth wandering through disparate countries and cultures yearning for an experience of truth; after finally meeting his spiritual teacher in India he has spent the last 50 years internalising that spiritual practice and sharing it with others. He is not only a guide, but a community builder, activist, and a New York Times Best selling author. Rooted in his study of ancient India’s mystic devotional tradition.</p>

                        </div>

                        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center py-5 ">
                            <div class="polaroid">
                                <img src="https://pbs.twimg.com/media/EysN5pjXAAApfyK.jpg" className="img-fluid" alt="temple" />

                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
export default AboutUs
import React from 'react'
import EventCarousel from './EventCarousel'
import UpcomingEvent from './UpcomingEvent'

function EventSection() {
    return (
        <div className='container-fluid mt-5 bg-pink'>
            <div className="row">
                <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-start border border-warning border-3 py-5 px-2">
                    <h3 className="font-large text-center fw-bold">
                        PAST EVENTS
                    </h3>
                    <div className='carousel-image mt-4'>
                        <EventCarousel />
                    </div>
                </div>
                {/* <div className='col-lg-1 border border-dark'></div> */}
                <div className="col-12 col-lg-6 border border-warning border-3 d-flex flex-column align-items-center py-5">
                    <h3 className="font-large text-center fw-bold">
                        UPCOMING EVENTS
                    </h3>
                    <UpcomingEvent/>

                </div>
            </div>
            {/* heyyyyyyy */}
        </div>
    )
}

export default EventSection
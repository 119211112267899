import { Routes,Route } from 'react-router-dom';
import './App.css';
import AboutUs from './components/AboutUs';
import Contactus from './components/Contactus';
import Courses from './components/Courses';
import Events from './components/Events';
import EventSection from './components/EventSection';
import Learn from './components/Learn';
import Navbar from './components/Navbar';
import Social from './components/Social';
import Subscribe from './components/Subscribe';
import Wallpaper from './components/Wallpaper';
import Welcome from './components/Welcome';
function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={
        <>
        <Navbar/>
        <Wallpaper/>
        <Subscribe/>
        <EventSection/> 
        <Welcome/> 
        <Learn/>
        <Social/>
        </>
      }/>
      <Route path="/events" element={<Events/>}/>
      <Route path="/contact-us" element={<Contactus/>}/>
      <Route path="/about-us" element={<AboutUs/>}/>
      <Route path="/courses" element={<Courses/>}/>
    </Routes>
    {/* <Events/> */}
    </>
  );
}

export default App;

import axios from "axios";
import React, { useRef } from "react";
import Navbar from './Navbar';
import Swal from "sweetalert2";

function Contactus() {
    let nameRef = useRef();
    let phoneRef = useRef();
    let emailRef = useRef();
    let queryRef = useRef();
    let sendQuery = async () => {
        console.log("hahahahahhahaha");
        // console.log(emailRef);
        try {
            let checks = nameRef.current.value &&
                phoneRef.current.value &&
                emailRef.current.value.indexOf("@") > -1 &&
                queryRef.current.value;
                // console.log(emailRef);
            let URL = process.env.REACT_APP_API_URL + "add-user";
            if (checks) {
                let sendData = {
                    name: nameRef.current.value,
                    email: emailRef.current.value,
                    phone: phoneRef.current.value,
                    query: queryRef.current.value
                }
                let { data } = await axios.post(URL, sendData);
                if (data.status) {
                    Swal.fire({
                        icon: "success",
                        title: data.message,
                    }).then(() => {
                        // window.location.assign("/");
                        window.location.reload();
                    });
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: data.message,
                    })
                }

            }
        } catch (error) {
            alert("error");
            console.log(error);
        }
    }
    return (
        <>
            <Navbar />
            <div class="event-wallpaper ">
                <section class="mb-4">


                    <h2 class="h1-responsive font-weight-bold text-center py-4">Contact us</h2>

                    <p class="text-center w-responsive mx-auto mb-5 font-lato">Do you have any questions? Or do you want to contact with us? Feel free to write us a message through this form.</p>

                    <div class="row d-flex justify-content-center font-lato">

                        <div class="col-md-8 mb-md-0 mb-6 d-flex justify-content-center flex-column">
                            <form id="contact-form" name="contact-form" method="POST">


                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="md-form mb-0">
                                            <input type="text" id="name" name="name" class="form-control" ref={nameRef} required />
                                            <label for="name" class="ms-1">Name</label>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="md-form mb-0">
                                            <input type="number" id="phone" name="phone" class="form-control" ref={phoneRef} />
                                            <label for="phone" class="ms-1">Phone Number</label>
                                        </div>
                                    </div>


                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="md-form mb-0">
                                            <input type="email" id="email" name="email" class="form-control" ref={emailRef} required />
                                            <label for="email" class="ms-1">Email</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-md-12">

                                        <div class="md-form">
                                            <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea" ref={queryRef}></textarea>
                                            <label className="ms-1" for="message">Your Query</label>
                                        </div>

                                    </div>
                                </div>


                            </form>

                            <div class="text-center text-md-left mb-4">
                                <a class="btn btn-outline-primary btn-md" onClick={sendQuery}>Send Message</a>
                            </div>
                            {/* <div class="status"></div> */}
                        </div>

                        <div class="col-md-12 text-center my-2">
                            <ul class="list-unstyled mb-0 d-flex flex-row justify-content-evenly align-items-baseline flex-wrap">
                                <li className=""><i class="fa fa-map-marker fa-2x mb-2"></i>
                                    <p>Flat A2 407 Merujeen Housing complex, Kamalgachi</p>
                                </li>

                                <li className=""><i class="fa fa-phone mt-4 fa-2x"></i>
                                    <p>+91 8768366427</p>
                                </li>

                                <li className=""><i class="fa fa-envelope mt-4 fa-2x"></i>
                                    <p>rmgmediaservices@gmail.com</p>
                                </li>
                            </ul>
                        </div>


                    </div>

                </section>
            </div>

        </>
    )
}
export default Contactus
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Learn() {
    const navigate = useNavigate();
    const goToCourses = (() => {
        navigate("/courses")
    })
    return (
        
        <div className='bg-light py-5'>
            <div className='text-center'><h1 className='tanger'>Get Spiritual Knowledge</h1></div>
            <div class="cards-list">

                <div class="card 1" onClick={goToCourses}>
                    <div class="card_image"> <img src="./image/youth.jpg" alt="youth"/> </div>
                    <div class="card_title title-white shadow-text">
                        <p>Youth</p>
                    </div>
                </div>
                <div class="card 1" onClick={goToCourses}>
                    <div class="card_image"> <img src="./image/family.jpg" alt="family"/> </div>
                    <div class="card_title title-white shadow-text">
                        <p>Family</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Learn
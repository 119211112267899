import React, { useState, useEffect } from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import axios from "axios"



const CustomForm = (({ status, message, onValidated }) => {


    const { modalOpen, setModalOpen } = useState(true);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [wa, setWa] = useState('');
    const [tel, setTel] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        email &&
            name &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
                MERGE1: name.split(" ")[0],
                MERGE2: name.split(" ")[name.split(" ").length - 1],
            });
        try {
            let sendData = {
                name: name,
                email: email,
                wa: wa,
                tel:tel
            };
            let URL = process.env.REACT_APP_API_URL+"/add-subscription";
            let { data } = await axios.post(URL, sendData);
        } catch (error) {
            alert("error");
            console.log(error);
        }

    }
    const setNameHandle = ((event) => {
        setName(event.target.value);
    })
    const setEmailHandle = ((event) => {
        setEmail(event.target.value);
    })
    const setWhatsappHandle = ((event) => {
        setWa(event.target.value);
    })
    const setTelegramHandle = ((event) => {
        setTel(event.target.value);
    })
    useEffect(() => {
        if (status === "success") clearFields();
        if (modalOpen && status === "success") clearFields();
    }, [status, modalOpen])

    const clearFields = () => {
        setName('');
        setEmail('');
        setWa('');
        setTel('');
    }
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <h3 className="text-center">
                {status === "success" ? "Subscription Successful!" :
                    status === "sending" ? "Subscribing........" :
                        status === "error" ? "Error!Try Again.." :
                            "Join us for future updates."}
            </h3>
            {status !== "success" ? (<><div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingName" placeholder='text' onChange={(event) => setNameHandle(event)} value={name} required />
                <label for="floatingName ">Name</label>
            </div>
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" onChange={(event) => setEmailHandle(event)} value={email} required />
                    <label for="floatingInput ">Email address</label>
                </div>
                <div class="form-floating mb-3">
                    <input type="number" class="form-control" id="floatingNumber" placeholder='number' onChange={(event) => setWhatsappHandle(event)} value={wa} required />
                    <label for="floatingNumber ">WhatsApp Number</label>
                </div>
                <div class="form-floating">
                    <input type="number" class="form-control" id="floatingNumber" placeholder='number' onChange={(event) => setTelegramHandle(event)} value={tel} />
                    <label for="floatingNumber ">Telegram Number</label>
                </div></>
            ) : null}

            {status === "success" ?
                (<div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-danger  my-4" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModalOpen(false)}>Close</button>
                </div>) :
                (
                    <div class="d-flex justify-content-center">
                        <button type="submit" class="btn btn-danger  my-4" value="Subscribe">Subscribe</button>
                    </div>
                )}



        </form>
    )
})
const Subscribe = (props) => {
    const url = `https://gmail.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;







    return (
        <div className='container-fluid w-75 mt-5 box-embed pt-4'>
            <div className='row d-flex justify-content-center align-items-center'>
                <div className="col-12 col-md-12 col-lg-10 text-center font-medium text-wrap mb-5">
                    To get email / WhatsApp / Telegram updates for upcoming Classes, Festivals, Events

                </div>
                <div className="col-12 col-md-12 col-lg-2 d-flex align-items-center font-medium mb-5 justify-content-center text-black">
                    {/* <i className='fa fa-3x fa-long-arrow-right me-5 arrow-icon'></i> */}
                    <div class="modal fade font-lato" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                {/* <div class="modal-header ">
                                    <h1 class="modal-title fs-5 " id="exampleModalLabel">Subscription form</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div> */}
                                <div class="modal-body fs-6">
                                    <MailchimpSubscribe
                                        url={url}
                                        render={({ subscribe, status, message }) => (
                                            <CustomForm
                                                status={status}
                                                message={message}
                                                onValidated={formData => subscribe(formData)}
                                            />
                                        )}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>

                    <button className='btn rounded-pill btn-danger font-medium' data-bs-toggle="modal" data-bs-target="#exampleModal">Subscribe</button>

                </div>

            </div>
        </div>

    )
}

export default Subscribe